import React from 'react';

export default function Arrow({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
      {...rest}
    >
      <g filter="url(#filter0_b_1627_1074)">
        <rect width="48" height="48" x="0.936" fill="#fff" rx="24"></rect>
        <rect
          width="47"
          height="47"
          x="1.436"
          y="0.5"
          stroke="#D8DAE6"
          rx="23.5"
        ></rect>
        <path
          stroke="#86899A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17.936 24h14m0 0l-7-7m7 7l-7 7"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_b_1627_1074"
          width="64"
          height="64"
          x="-7.064"
          y="-8"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="4"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1627_1074"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1627_1074"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
