import React from 'react';

export default function PlayIcon() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 'calc(60% - 95px)',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="149"
        height="149"
        fill="none"
        viewBox="0 -10 149 149"
      >
        <g filter="url(#filter0_dd_1627_1120)">
          <rect
            width="95.132"
            height="95.132"
            x="27"
            y="16"
            fill="#fff"
            rx="47.566"
          ></rect>
          <mask
            id="path-2-outside-1_1627_1120"
            width="28"
            height="32"
            x="64.711"
            y="47.711"
            fill="#000"
            maskUnits="userSpaceOnUse"
          >
            <path fill="#fff" d="M64.711 47.711h28v32h-28z"></path>
            <path d="M69.611 77.693c.496 0 1.053-.124 1.549-.434l17.837-10.9c.867-.496 1.424-1.487 1.424-2.54 0-.99-.557-1.982-1.424-2.477l-17.837-10.9a2.94 2.94 0 0 0-1.549-.434c-1.734 0-2.973 1.362-2.973 2.91V74.72c0 1.61 1.239 2.973 2.973 2.973"></path>
          </mask>
          <path
            fill="#1C3EFF"
            d="M69.611 77.693c.496 0 1.053-.124 1.549-.434l17.837-10.9c.867-.496 1.424-1.487 1.424-2.54 0-.99-.557-1.982-1.424-2.477l-17.837-10.9a2.94 2.94 0 0 0-1.549-.434c-1.734 0-2.973 1.362-2.973 2.91V74.72c0 1.61 1.239 2.973 2.973 2.973"
          ></path>
          <path
            fill="#1C3EFF"
            d="m71.16 77.26-.69-1.128-.01.007zm17.837-10.901-.656-1.148-.017.01-.016.01zm0-5.017-.69 1.127.017.01.017.01zm-17.837-10.9-.7 1.12.01.007zM69.61 79.013c.704 0 1.513-.174 2.249-.635l-1.4-2.24c-.255.159-.562.232-.849.232zm2.238-.627 17.837-10.901-1.378-2.255-17.837 10.9zm17.803-10.881c1.304-.745 2.09-2.19 2.09-3.687H89.1c0 .608-.328 1.146-.759 1.392zm2.09-3.687c0-1.451-.8-2.887-2.09-3.624l-1.31 2.294c.443.254.758.8.758 1.33zm-2.056-3.605-17.837-10.9-1.378 2.255 17.837 10.9zM71.86 49.321a4.26 4.26 0 0 0-2.249-.634v2.642c.287 0 .594.074.848.233zm-2.249-.634c-2.482 0-4.294 1.972-4.294 4.232h2.643c0-.837.665-1.59 1.651-1.59zm-4.294 4.232v21.8h2.643v-21.8zm0 21.8c0 2.304 1.795 4.295 4.294 4.295V76.37c-.968 0-1.651-.734-1.651-1.651z"
            mask="url(#path-2-outside-1_1627_1120)"
          ></path>
        </g>
        <defs>
          <filter
            id="filter0_dd_1627_1120"
            width="147.983"
            height="147.983"
            x="0.575"
            y="0.145"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feMorphology
              in="SourceAlpha"
              radius="5.285"
              result="effect1_dropShadow_1627_1120"
            ></feMorphology>
            <feOffset dy="10.57"></feOffset>
            <feGaussianBlur stdDeviation="5.285"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.188235 0 0 0 0 0.803922 0 0 0 0.04 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1627_1120"
            ></feBlend>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feMorphology
              in="SourceAlpha"
              radius="5.285"
              result="effect2_dropShadow_1627_1120"
            ></feMorphology>
            <feOffset dy="10.57"></feOffset>
            <feGaussianBlur stdDeviation="15.855"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.188235 0 0 0 0 0.803922 0 0 0 0.1 0"></feColorMatrix>
            <feBlend
              in2="effect1_dropShadow_1627_1120"
              result="effect2_dropShadow_1627_1120"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect2_dropShadow_1627_1120"
              result="shape"
            ></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
}
